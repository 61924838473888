<template>
    <div class="container d-flex flex-column justify-content-center align-items-center full-height text-center">
        <h1>Oops! Esse dashboard está meio vazio :/</h1>
        <p>Ele foi criado mas nenhum recurso foi adicionado. Acesse <horizontal-link link="app.ApontaHora.com"/> e faça login. Depois abra o menu, navegue até <strong>Dashboards</strong>, edite este dashboard e adicione recursos.</p>
        <p>Ah! E não precisa atualizar a página! Depois de salvar as alterações os recursos aparecerão aqui automaticamente ;)</p>
    </div>
</template>

<script>
import HorizontalLink from '../HorizontalLink.vue'
export default {
    name: 'EmptyDashboard',
    components: {HorizontalLink}
}
</script>

<style scoped>
.full-height {
    height: 90vh
}

</style>
