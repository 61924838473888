const Handler = require('./Handler')
const backend_codes = require("../../backend_codes");

class ReaderHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.READER_H1_CHANGE
    }

    _execute(msg) {
        // {h1: status, resourceId}
        const data = msg.content
        const resource = this._getResource(data.resourceId)
        if (!resource)
            return

        resource.h1 = data.h1
    }
}

module.exports = {ReaderHandler}