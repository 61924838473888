const backend_codes = require("../../backend_codes");
const Handler = require("./Handler");

class OsUpdatedHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.OS_CHANGED
    } 

    _execute(msg) {
        for(const data of msg.content) {
            const resource = this._getResource(data.id)
            if (!resource || !resource.os)
                continue

            resource.os.client_name = data.os.client_name
            resource.os.description = data.os.description
            resource.os.cards = data.os.cards
            resource.os.number = data.os.number
            resource.os.vehicle_info = data.os.vehicle_info
        }
    }
}

class OsCanceledHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.OS_REMOVED
    }

    _execute(msg) {
        const osId = msg.os
        this._data.resources.forEach(resource => {
            if (!resource.os || !resource.os.id !== osId)
                return

            resource.os = null
        })
    }
}

module.exports = {
    OsUpdatedHandler,
    OsCanceledHandler
}