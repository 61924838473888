const backend_codes = require("../../backend_codes");
const Handler = require("./Handler");

class DashboardUpdateHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.DASHBOARD_UPDATED
    }

    _execute(msg) {
        this._setDashboardName(msg.dashboard_name)
        this._reloadResources(msg.content)
    }
}

class DashboardRemovedHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.DASHBOARD_REMOVED
    }

    _execute() {
        this._data.modal.title = 'Oops! Esse dashboard foi excluído'
        this._data.modal.message = 'Recarregue a página para escolher outro dashboard'
        this._data.showModal = true
    }
}

module.exports = {
    DashboardUpdateHandler,
    DashboardRemovedHandler
}