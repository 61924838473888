<template>
    <i class="fas fa-stopwatch"></i> {{elapsedTime}}
</template>

<script>
import {getTimeSyncFactor, getSyncedElapsedSeconds, elapsedSecondsToTimeStr} from '../../utils/TimeSync'

export default {
    name:'CardCounter',
    props: {
        timestamp: Object,
        elapsedSeconds: {type: Number, default: 0}
    },

    emits: ['tick'],

    data() {
        return {
            timeDiff: null,
            innerElapsedSeconds: 0,
            timer: null,
            accumulatedSeconds: 0,
        }
    },

    computed: {
        elapsedTime() {
            return elapsedSecondsToTimeStr(this.innerElapsedSeconds)
        }
    },

    watch: {
        elapsedSeconds() {
            this.timeDiff = getTimeSyncFactor(this.timestamp, this.elapsedSeconds)
        }
    },

    mounted() {
        const setTimer = () => {
            this.timer = window.setTimeout(() => {
                this.innerElapsedSeconds = getSyncedElapsedSeconds(this.timestamp, this.timeDiff)
                if (Math.floor(this.innerElapsedSeconds) % 36 == 0)
                    this.$emit('tick')
                setTimer()
            }, 1000)
        }
        this.timeDiff = getTimeSyncFactor(this.timestamp, this.elapsedSeconds)
        setTimer()
    },
    unmounted() {
        window.clearTimeout(this.timer)
    }
}
</script>