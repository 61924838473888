<template>
    <a :href="fullLink" target="_blank">
        <img 
            src="../assets/mobile_logo.png" 
            alt="Mobile logo"> 
        <span class="link" :class="{'link-name': !white, 'link-name-white': white}">{{linkName}}</span>
        <span class="link link-domain">{{linkDomain}}</span>
    </a>
</template>

<script>
export default {
    name:'HorizontalLink',
    props: {
        link: {
            type: String,
            default: 'ApontaHora.com'
        },
        white: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        fullLink() {
            return `https://${this.link}`
        },
        linkName() {
            return this.link.substr(0, this.link.indexOf('.com'))
        },
        linkDomain() {
            return this.link.substr(this.link.indexOf('.com'))
        }
    }
}
</script>

<style scoped>
a{text-decoration: none}
a:hover{text-decoration: underline}
img {height: 1.5em}
.link{font-weight: bold}
.link-name{color: black}
.link-name-white{color: white}
.link-domain{color: orangered}
</style>