const backend_codes = require("../../backend_codes");
const Handler = require("./Handler");

class ResourceChangedHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.RESOURCE_CHANGED
    }

    _execute(msg) {
        const data = msg.content
        const resource = this._getResource(data.id)
        if (!resource)
            return

        resource.name = data.name
        resource.total_worked_hours = data.total_worked_hours
        resource.count_worked_os = data.count_worked_os
        // FIXME foto não atualizada durante a remoção
        if (data.picture)
            resource.picture = data.picture
    }
}

class ResourceDeletedHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.RESOURCE_DELETED
    }

    _execute(msg) {
        const resIdx = this._getResourceIndex(msg.id)
        if (resIdx == -1)
            return

        this._data.resources.splice(resIdx, 1)
    }
}

class ResourceOsUpdateHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.RESOURCE_OS_UPDATED
    }

    _execute(msg) {
        const data = msg.content
        const resource = this._getResource(data.id)
        if (!resource)
            return

        resource.count_worked_os = data.count_worked_os
        resource.total_worked_hours = data.total_worked_hours
        if (data.os) {
            data.os.note_start_timestamp = new Date(data.os.note_start_timestamp)
            resource.os = data.os
        }
        else
            resource.os = null

        if (data.last_note) {
            data.last_note.timestamp = new Date(data.last_note.timestamp)
            resource.last_note = data.last_note
        }
        else
            resource.last_note = null
    }
}

module.exports = {
    ResourceChangedHandler,
    ResourceDeletedHandler,
    ResourceOsUpdateHandler
}