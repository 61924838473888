<template>
    <div class="col">
        <div class="af-card p-2 border border-5 rounded" :class="{'border-success': resource.os && resource.h1 >= 0, 'border-danger': !resource.os && resource.h1 >= 0, 'border-warning': resource.h1 === -1, 'border-teste': resource.h1 === -2}">
            <!-- Cabeçalho: imagem, nome, cronômetros etc -->
            <header class="d-flex">
                <!-- Foto do recurso -->
                <div 
                    class="picture flex-shrink-0"
                    :style="{backgroundImage: 'url('+adjustedPicture+')'}"></div>
                <!-- Cronômetros, nome, cartões etc -->
                <div class="flex-grow-1 p-1">
                    <!-- Nome, quantidade de OS e total de horas trabalhadas no dia -->
                    <div class="d-flex justify-content-between">
                        <h1 class="resource-name">{{resource.name}}</h1>
                        <div class="flex-shrink-0 ms-1">
                            <span>
                                <i class="far fa-copy"></i> {{resource.count_worked_os}}
                            </span>
                            <span class="ms-3">
                                <i class="fas fa-history"></i> {{totalDayHours}}
                            </span>
                        </div>
                    </div>
                    <!-- OS e horário de início do apontamento -->
                    <div class="d-flex justify-content-between fixed-height">
                        <p class="m-0">
                            <template v-if="resource.os">
                                <span v-if="isClientNumber"><i class="far fa-sticky-note"></i> {{osInfoCarWorkshop}}</span>
                                <span v-else><i class="far fa-sticky-note"></i> {{osInfo}}</span>
                            </template>
                        </p>
                        <p class="m-0 flex-shrink-0">
                            <template v-if="resource.os || resource.last_note">
                                <span v-if="resource.os"><i class="far fa-play-circle"></i> {{noteStartTime}}</span>
                                <span v-else><i class="fas fa-pause-circle"></i> {{lastNoteTimestamp}}</span>
                            </template>
                        </p>
                    </div>
                    <!-- Cartões e cronômetro de apontamento -->
                    <div class="d-flex justify-content-between mt-1 fixed-height">
                        <p class="m-0"><os-cards v-if="resource.os" :cards="resource.os.cards"/></p>
                        <p class="m-0 ms-2 flex-shrink-0">
                            <card-counter 
                                v-if="resource.os"
                                :elapsedSeconds="resource.os.elapsed_seconds"
                                :timestamp="resource.os.note_start_timestamp"
                                @tick="calculateTotalHours"/>
                            <card-counter 
                                v-else-if="resource.last_note"
                                :elapsedSeconds="resource.last_note.elapsed_seconds"
                                :timestamp="resource.last_note.timestamp"/>
                        </p>
                    </div>
                    <!-- Nome do cliente -->
                    <template v-if="resource.os">
                        <div class="d-flex justify-content-between mt-1">
                            <p class="m-0 client-name">
                                <i class="far fa-user"></i> {{resource.os.client_name || 'N/A'}}
                            </p>
                        </div>
                    </template> 
                </div>
            </header>
            <!-- Informações do veículo -->
            <template v-if="resource.os">
                <div class="vehicle-info mt-1">
                    <span v-if="isCarWorkshop"><i class="fas fa-car"></i></span>
                    <span v-else><i class="fas fa-info-circle"></i></span>
                    {{resource.os.vehicle_info || 'N/A'}}
                </div>
            </template>
            <!-- Descrição da OS -->
            <section class="card-description mt-1" v-if="resource.os">
                {{resource.os.description}}
            </section>
        </div>
    </div>
</template>

<script>
import CardCounter from './CardCounter.vue'
import OsCards from './OsCards.vue'
import {getTimeSyncFactor, getSyncedElapsedSeconds} from '../../utils/TimeSync'

export default {
    name:'DashboardCard',
    props:{
        resource: Object
    },
    data() {
        return {
           totalHours: 0,
           timeDiff: 0, // usado para sincronizar a hora da máquina do cliente com a hora do servidor, para fins de cálculos do total de horas trabalhas 
        }
        
    },
    watch: {
        'resource.total_worked_hours': function () {this.calculateTotalHours()},
    },
    computed: {
        adjustedPicture() {
            return `data:image;base64,${this.resource.picture.replace(/\n/g, '')}`
        },
        osInfo() {
            return `${this.resource.os.number}/${this.resource.os.year - 2000}`
        },
        osInfoCarWorkshop() {
            return `${this.resource.os.number}`
        },
        noteStartTime() {
            const ts = this.resource.os.note_start_timestamp
            return `${String(ts.getHours()).padStart(2, '0')}:${String(ts.getMinutes()).padStart(2, '0')}:${String(ts.getSeconds()).padStart(2, '0')}`
        },
        lastNoteTimestamp() {
            return this.resource.last_note.timestamp.toLocaleString()
        },
        totalDayHours() {
            return `${Number(this.totalHours).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}h`
        },
        isClientNumber() {
            return this.resource.os.os_is_client_number
        },
        isCarWorkshop() {
            return this.resource.os.company_is_car_workshop
        }
    },
    methods: {
        calculateTotalHours() {
            this.totalHours = this.resource.total_worked_hours
            if (this.resource.os)
                this.totalHours += getSyncedElapsedSeconds(this.resource.os.note_start_timestamp, this.timeDiff)/3600
        }
    },
    created() {
        this.timeDiff = (this.resource.os) ? getTimeSyncFactor(this.resource.os.note_start_timestamp, this.resource.os.elapsed_seconds) : 0
        this.calculateTotalHours()
    },
    components: {CardCounter, OsCards}
}
</script>

<style scoped>
.picture {height:110px; width:110px; border:1px solid #ccc;background-size: cover; background-position:center center}
.resource-name {font-size:1.3em; height: 1.2em; overflow: hidden}
.fixed-height{height:1.3em; overflow:hidden; word-break: break-all;}
.client-name {overflow: hidden; height: 1.5em; word-break: break-all;}
.border-teste {border-color: purple !important}
p, span {font-size: 1em;}
@media only screen and (max-width: 1200px) {
    .resource-name {font-size: 1.2em}
    p,span {font-size: .9em}
    .ms-3{margin-left: 13px!important}
}
@media only screen and (max-width: 768px) {
    .resource-name {font-size: 1em}
    p,span {font-size:.8em}
    .ms-3 {margin-left: 10px!important}
}
@media only screen and (max-width: 576px) {
    .resource-name {font-size: 1.1em;}
    p,span {font-size: .8em;}
    .ms-3{margin-left: 16px!important}
}
.af-card {height: 100%; background-color: #fff;box-shadow: 2px 2px 5px #AAA; transition: transform 0.2s, box-shadow 0.2s}
.af-card:hover{transform: scale(102%) translate(-5px, -10px); box-shadow: 3px 10px 10px #BBB;}
.vehicle-info {white-space:nowrap; overflow:hidden; text-overflow:ellipsis}
</style>