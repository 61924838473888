/*Exemplo de objeto do dashboard
 {
  id: 1, # ID do recurso
  name: 'S - Faustão - LAAB', # nome do recurso
  priority: x, # Número de prioridade do recurso
  picture: <base64>, # Foto, em base64
  count_worked_os: 4, # Total de OS trabalhada no dia
  total_worked_hours: 1.03, # Total de horas trabalhada no dia
  os: { # OS atual (se NULL, nenhuma OS atual)
    card: 1, # número docartão
    number: 186, # Número da OS
    year: 2021, # Ano da OS
    client_name: 'GRV SOFTWARE', # Nome do cliente
    # Descrição da OS  
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet malesuada diam pharetra aliquet. Donec varius et eros ut lacinia. Etiam in urna rhoncus',
    note_start_timestamp: new Date('2021-07-14T09:16:45') # Hora de início do trabalho
    elapsed_seconds: 0 # Segundos decorridos desde a hora de início do trabalho
  }
},
*/

function inject() {
    console.log('SIMULATOR')
    return [
        {
            id: 1,
            name: 'Resource 1',
            priority: 0,
            count_worked_os: 6,
            total_worked_hours: 4.5,
            picture: '',
            os: null,
            last_note: {
                timestamp: new Date(Date.now() - 40000),
                elapsed_seconds: 40
            }
        },
        {
            id: 3,
            name: 'Resource 3 with a really long name',
            priority: 1,
            count_worked_os: 6,
            total_worked_hours: 4.5,
            picture: '',
            os: null,
            last_note: {
                timestamp: new Date(Date.now() - 40000),
                elapsed_seconds: 40
            }
        },
        {
            id: 2,
            name: 'Resource 2 with a really long name',
            priority: 2,
            count_worked_os: 3,
            total_worked_hours: 3.690,
            picture: '',
            os: {
                cards: [1001, 1002, 1003],
                number: 20,
                year: 2021,
                client_name: 'GRV SOFTWARE, with a really long name',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet malesuada diam pharetra aliquet.',
                note_start_timestamp: new Date(Date.now() - 40000),
                elapsed_seconds: 40
            },
            last_note: null,
        },
        {
            id: 1,
            name: 'Resource 1',
            priority: 0,
            count_worked_os: 6,
            total_worked_hours: 4.5,
            picture: '',
            os: null,
            last_note: {
                timestamp: new Date(Date.now() - 40000),
                elapsed_seconds: 40
            }
        },
        {
            id: 3,
            name: 'Resource 3 with a really long name',
            priority: 1,
            count_worked_os: 6,
            total_worked_hours: 4.5,
            picture: '',
            os: null,
            last_note: {
                timestamp: new Date(Date.now() - 40000),
                elapsed_seconds: 40
            }
        },
        {
            id: 2,
            name: 'Resource 2 with a really long name',
            priority: 2,
            count_worked_os: 3,
            total_worked_hours: 3.690,
            picture: '',
            os: {
                cards: [1001, 1002, 1003],
                number: 20,
                year: 2021,
                client_name: 'GRV SOFTWARE, with a really long name',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet malesuada diam pharetra aliquet.',
                note_start_timestamp: new Date(Date.now() - 40000),
                elapsed_seconds: 40
            },
            last_note: null,
        },

    ]
}

module.exports = inject