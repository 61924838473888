<template>
    <div class="container-fluid p-0 m-0 page pb-3">
        <!-- modal de carga: Aparece quando precisa-se exibir alguma mensagem direta ao usuário. Ex: conexão caiu -->
        <loading-modal 
            v-if="showModal"
            :title="modal.title" :message="modal.message"/>

        <!-- cabeçalho do dashboard: logotipo, nome do dashboard e botão de sair -->
        <header class="d-flex align-items-center mb-1 px-2 pt-1" v-if="!$route.query.noHeader">
            <horizontal-link/>
            <strong class="text-center flex-grow-1">{{dashboardName}} ({{$route.params.code}})</strong>
            <button 
                class="btn btn-secondary me-2"
                @click="goFullscreen">
                <i class="fas" :class="{'fa-expand': !isFullscreen, 'fa-compress': isFullscreen}"></i>
            </button>
            <button class="btn btn-primary" @click="$router.replace('/')">
                <i class="fas fa-sign-out-alt"></i>
            </button>
        </header>

        <!-- Corpo do dashboard: cards -->
        <div class="container-fluid">
            <template v-if="resources.length > 0">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-4 gy-2">
                    <dashboard-card v-for="resource in ascSortedResources" :key="resource.id" :resource="resource"/>
                </div>
            </template>
            <empty-dashboard v-else-if="resources.length == 0"/>
        </div>
    </div>
</template>

<script>
import simulatorResourceInjector from '../utils/SimulatorResourceInjector'
import DashboardCard from '../components/Dashboard/DashboardCard.vue'
import LoadingModal from '../components/LoadingModal.vue'
import EmptyDashboard from '../components/Dashboard/EmtpyDashboard.vue'
import HorizontalLink from '../components/HorizontalLink.vue'
import WebSocketClient from '../classes/WebSocketClient'
import HandlersFactory from '../classes/backendHandlers/HandlersFactory'
import Login from '../classes/Login'
import SweetAlert from 'sweetalert2'
import config from '../config'

export default {
    name: 'Dashboard',
    data() {
        return {
            dashboardName: '',
            showModal: true,
            dashboardLoaded: false,
            modal: {
                title: 'Conectando com o servidor',
                message: 'Aguarde...'
            },
            backend: null,
            resources: [],
            token: '',
            isFullscreen: false
        }
    },

    computed: {
        ascSortedResources() {
            return this.resources.map(e => e).sort((a, b) => a.priority - b.priority)
        }
    },

    methods: {
        goFullscreen() {
            if (!this.isFullscreen)
                document.body.requestFullscreen()
                    .then(() => this.isFullscreen = true)
                    .catch(err => SweetAlert.fire({
                        title: 'Seu dispositivo não suporta tela cheia',
                        text: `Erro: ${err.message} (${err.name})`,
                        icon: 'info'
                    }))
            else
                document.exitFullscreen().then(() => this.isFullscreen = false)
        },
        // métodos em uso
        onBackendConnected() {
            this.modal.title = 'Carregando o dashboard'
            this.modal.message = 'Aguarde...'
            // this.backend.doLogin(this.token)
        },

        onBackendDisconnected(code) {
            if (code === 4006) {
                this.showModal = true
                this.modal.title = "Há uma nova versão do dashboard disponível ;)"
                this.modal.message = 'Por favor, atualize a página para receber a atualização'
                return false
            }

            if (code === 4000  || code === 4004) {
                this.$router.replace('/')
                return false
            }
            else if (code === 4001) {
                try {
                    const login = new Login(this.$route.params.code, false)
                    login.doLogin()
                        .then(ok => {
                            if (ok) {
                                this.token = login.token
                                this.connectToBackend()
                            }
                            else
                                this.$router.replace('/')
                        })
                        .catch(() => {
                            this.$router.replace('/')
                        })
                        return false
                }
                catch(e) {
                    console.log(e)
                    this.$router.replace('/')
                }
            }

            this.showModal = true
            this.modal.title="Sem conexão com o servidor"
            this.modal.message="Não se preocupe. Reconectaremos assim que estiver tudo bem ;)"
            return true
        },

        connectToBackend() {
            this.backend = new WebSocketClient(this.onBackendConnected, this.onBackendDisconnected, `resourceClient-${this.$route.params.code}`)
            this.backend.addHandlers(HandlersFactory(this))
        },
    },

    mounted() {
        if (this.$route.params.code == 'simul' && !this.$route.query.empty) {
            console.log('Dashboard de simulação')
            console.log(`Configurações: ${JSON.stringify(config)}`)
            console.log(`Environment: ${JSON.stringify(process.env)}`)
            this.dashboardName = 'SIMULAÇÃO'
            Array.prototype.push.apply(this.resources, simulatorResourceInjector())
            this.showModal = false
        }
        else {
            this.token = window.localStorage.getItem('token')
            if (!this.token || this.$route.params.code != window.localStorage.getItem('code'))
                this.onBackendDisconnected(4001)
            else
                this.connectToBackend()
        }
    },

    beforeUnmount() {
        if (this.backend)
            this.backend.close()
    },

    components: {DashboardCard, LoadingModal, EmptyDashboard, HorizontalLink}
}
</script>

<style scoped>
    .page {height: 100vh; background-color: #efefef; overflow-y: auto;}
    .header-image {
        height:30px
    }
</style>